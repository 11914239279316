import { Options, Vue } from 'vue-class-component'
import { Component, Watch } from 'vue-property-decorator'
import { Posts } from '@/services/PostsDataService'
import SysPost, { SysPostCategory, SysPostVersion } from '@/types/SysPost'

type dataReturnType = { totalPages: number; indlaegs: any; indlaegversionering: any; indlaegkategori: any; postCategoryTitleValue: string; postSearchInputValue: string; error: any; }

// @Options({
//   props: {
//     instanceType: Number
//   }
// })
export default class theNewsArchive extends Vue {
  instanceType!: number
  private indlaegs: SysPost[] = []
  private indlaegversionerings: SysPostVersion[] = []
  private indlaegkategoris: SysPostCategory[] = []
  private indlaegversionering = {} as SysPostVersion
  private indlaegkategori = {} as SysPostCategory
  error: any = null
  private currentListSortingOrder = 'indlaeg_udgivet:desc'
  private tempPost = {} as SysPost
  postSearchInputValue = ''
  postSearchTerm = ''
  postCategoryTitleValue = 'Vælg her'
  postCategoryTitleOptions: string[] = []
  private currentPage = 1
  private totalPages = 0
  private totalNumberOfPages = 0
  pageSizeValue = 25
  pageSizeValueString = '25'
  pageSizeOptions: string[] = ['10', '25', '100']
  filterByYearValue = 'Vælg år'
  filterByYearOptions: string[] = ['Vælg år', '2022', '2021', '2020', '2019', '2018', '2017', '2016', '2015', '2014', '2013', '2012', '2011']
  yearSearchTerm = ''
  private oldSort = ''
  toDaysDate = new Date().toISOString()
  filterByYearDate = ''

  readonly name : string = 'NewsArchive'
  data (): dataReturnType {
    return {
      indlaegs: this.indlaegs,
      indlaegversionering: this.indlaegversionering,
      indlaegkategori: this.indlaegkategori,
      postCategoryTitleValue: this.postCategoryTitleValue,
      postSearchInputValue: this.postSearchInputValue,
      error: this.error,
      totalPages: this.totalPages
    }
  }

  @Watch('pageSizeValueString')
  onPageSizeValueChange (newVal: any) : void {
    this.pageSizeValue = Number(newVal)
    this.currentPage = 1
    this.retrieveNewsArchive()
  }

  @Watch('filterByYearValue')
  onfilterByYearValueChange (newYear: string) : void {
    if (this.filterByYearValue !== 'Vælg år') {
      this.filterByYearDate = `indlaeg_udgivet_lte=${this.filterByYearValue}-12-31T23:59:59.000Z&indlaeg_udgivet_gte=${this.filterByYearValue}-01-01T00:00:01.000Z`
      console.log(this.filterByYearDate)
      this.currentPage = 1
      this.retrieveNewsArchive()
    }
  }

  public columnSort (sortBy : string) : void {
    if (sortBy === 'reset') {
      this.currentListSortingOrder = 'indlaeg_udgivet:desc'
      this.oldSort = ''
      this.filterByYearValue = 'Vælg år'
      this.filterByYearDate = ''
      this.currentPage = 1
    }
    if (sortBy === 'title') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'indlaeg_versionerings_id.indlaeg_versionering_titel:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'indlaeg_versionerings_id.indlaeg_versionering_titel:desc'
        this.oldSort = ''
      }
    }
    if (sortBy === 'udgivet') {
      if (this.oldSort === '' || this.oldSort !== sortBy) {
        this.currentListSortingOrder = 'indlaeg_udgivet:asc'
        this.oldSort = sortBy
      } else {
        this.currentListSortingOrder = 'indlaeg_udgivet:desc'
        this.oldSort = ''
      }
    }

    this.retrieveNewsArchive()
  }

  public getIndexOfPostVersion (postVersion: number, singlePostEntryObj: SysPost = this.tempPost) : number {
    // const retVal = this.indlaegs.indlaeg_versionerings_id.findIndex(x => x.indlaeg_versionering_version === postVersion)
    const retVal = singlePostEntryObj.indlaeg_versionerings_id.findIndex(x => x.indlaeg_versionering_version === postVersion)
    // console.log('Index of post version = ' + retVal + ' ; post versioning object = ' + JSON.stringify(singlePostEntryObj.indlaeg_versionerings_id))

    return (retVal === undefined ? -1 : retVal)
  }

  public getCategoryIdFromCategoryTitle (categoryTitle: string) : number | null {
    const retVal = this.indlaegkategoris.find(x => x.indlaeg_kategori_titel === categoryTitle)?.id
    // console.log('Category id found = ' + retVal)

    return (retVal === undefined ? 0 : retVal)
  }

  public forceRerenderPostsList () : void {
    this.error = 'Opdaterer ...'
    this.$nextTick(() => {
      this.error = null
    })
  }

  public findPost () : void {
    console.log('Search post.')
    this.postSearchTerm = this.postSearchInputValue
    this.retrieveNewsArchive()
  }

  public turnToNewPage (pageChange : number) : void {
    this.currentPage += pageChange

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage >= this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveNewsArchive()
  }

  public jumpToPage (pageNumber : number) : void {
    this.currentPage = pageNumber

    if (this.currentPage < 1) {
      this.currentPage = 1
    } else if (this.currentPage > this.totalNumberOfPages) {
      this.currentPage = this.totalNumberOfPages
    }

    this.retrieveNewsArchive()
  }

  public retrieveNewsArchive () : void {
    Posts.PostsDataService.getCount(`indlaeg_type=0&indlaeg_status=true&indlaeg_udgivet_lt=${this.toDaysDate}&${this.filterByYearDate}`)
      .then((response) => {
        this.totalPages = response.data
        this.totalNumberOfPages = Math.ceil(this.totalPages / this.pageSizeValue)
        console.log(this.currentPage + ' of ' + this.totalNumberOfPages + ' - total number of entries: ' + this.totalPages)
      })
      .catch((err) => {
        this.error = err
      })
    if (this.postSearchTerm.length > 0) {
      Posts.PostsDataService.findBySearchTerm(true, true, this.postSearchTerm, this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, `indlaeg_type=0&indlaeg_status=true&indlaeg_udgivet_lt=${this.toDaysDate}&${this.filterByYearDate}`)
        .then((response) => {
          this.indlaegs = response.data
          this.forceRerenderPostsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
        })
    } else {
      Posts.PostsDataService.getAll(this.currentListSortingOrder, { slicemode: 1, start: 0, limit: -1, page: (this.currentPage - 1), pagesize: this.pageSizeValue, totalcount: this.totalPages }, `indlaeg_type=0&indlaeg_status=true&indlaeg_udgivet_lt=${this.toDaysDate}&${this.filterByYearDate}`)
        .then((response) => {
          this.indlaegs = response.data
          this.forceRerenderPostsList()
          this.$forceUpdate()
        })
        .catch((err) => {
          this.error = err
        })
    }
  }

  public goToNews (newsSlug: string) : void {
    this.$router.push({ name: 'Nyheder', params: { indlaegSlug: newsSlug } })
  }

  async mounted () : Promise<void> {
    this.retrieveNewsArchive()
  }
}
